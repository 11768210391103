import React from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

import PermissionGateway from "../components/permission/PermissionGateway";
import {
  Activitysidebar,
  AnalyticsSidebar,
  Checklistsidebar,
  ClientFeedbackicon,
  Clientsidebar,
  Conferencesidebar,
  Contactsidebar,
  Dashboardsidebar,
  Deliverablesidebar,
  EAppsicon,
  Emanagesettingsidebar,
  Employeesidebar,
  Faciltysettingsidebar,
  HRsidebar,
  MintsMeetingsidebar,
  Opportunitysidebar,
  Organisationsidebar,
  Projectquerysidebar,
  Projectsidebar,
  Repeaticon,
  ReportBuilderSidebar,
  ReportSidebar,
  ResourcePlannerIcon,
  Salessidebar,
  Settingsidebar,
  SidebarTimesheet,
  Subprojectsidebar,
  // Tasksidebar,
  Timesheetsidebar,
  Trashsidebar,
  Variotionordrsidebar,
} from "../assets/svgicons";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const Sidebar = () => {
  const location = useLocation().pathname;
  // Active Class

  //responsive
  const isMobile = useMediaQuery("(max-width:991px)");

  const active = (navTab) => {
    let classtring = "";
    switch (navTab) {
      case "/dashboard":
        if (location.includes("/dashboard")) {
          classtring = "active";
        }
        break;
      case "/eapps":
        if (location.includes("/eapps")) {
          classtring = "active";
        }
        break;
      case "/employees":
        if (
          location.split("/")[1].includes("employees")

          // location.includes('/employees/active') ||
          // location.includes('/employees/inactive') ||
          // location.includes('/employees/draft') ||
          // location.includes('/employees/add') ||
          // location.includes('/employees/edit') ||
          // location.includes('/employees/details/')
          // location.includes('/employee-exit')
        ) {
          classtring = "active";
        }
        break;
      case "/hr-settings":
        if (
          location.split("/")[1].includes("hr-settings") ||
          location.includes("/add-user-access-level") ||
          location.includes("/view-user-access-level/") ||
          // location.includes('/add-user-access-level') ||
          location.includes("/edit-user-access-level/") ||
          location.includes("/clone-user-access-level/")
          // location.includes('/hr-settings/employee-exit-approvals')
        ) {
          classtring = "active";
        }
        break;
      case "/sales-settings":
        if (location.includes("/sales-settings")) {
          classtring = "active";
        }
        break;
      case "/facilities-settings":
        if (location.includes("/facilities-settings")) {
          classtring = "active";
        }
        break;
      case "/emanage-settings":
        if (location.includes("/emanage-settings")) {
          classtring = "active";
        }
        break;
      case "/production-settings":
        if (location.includes("/production-settings")) {
          classtring = "active";
        }
        break;
      case "/organisations":
        if (
          location.split("/")[1].includes("organisations")
          // ||
          // location.includes('/organisations/add') ||
          // location.includes('/organisations/edit') ||
          // location.includes('/organisation') ||
          // location.includes('/organisations/contacts/') ||
          // location.includes('/organisations/tasks/')
        ) {
          classtring = "active";
        }
        break;
      case "/contacts":
        if (
          location.split("/")[1].includes("contacts")
          // location.includes('/contacts') ||
          // location.includes('/contacts/add') ||
          // location.includes('/contacts/edit')
        ) {
          classtring = "active";
        }
        break;
      // case '/tasks':
      //   if (
      //     location.split('/')[1].includes('tasks')
      //     // location.includes('/tasks') ||
      //     // location.includes('/tasks/add') ||
      //     // location.includes('/tasks/edit')
      //   ) {
      //     classtring = 'active';
      //   }
      //   break;

      case "/projects":
        if (
          location.split("/")[1] === "projects" ||
          location.includes("/post-implementation-review/")

          // location.includes('/projects')  ||
          // location.includes('/projects/create') ||
          // location.includes('/project-unarchive/') ||
          // location.includes('/post-implementation-review/') ||
          // location.includes('/projects/contacts/') ||
          // location.includes('/projects/project-queries/')
        ) {
          classtring = "active";
        }
        break;
      case "/clients":
        if (
          location.split("/")[1] === "clients"
          // location.includes('/checklist/details')
          // location.includes('clients') ||
          // location.includes('/client-overview/') ||
          // location.includes('/contracts') ||
          // location.includes('/contracts/add') ||
          // location.includes('/contracts/edit')
        ) {
          classtring = "active";
        }
        break;
      case "/checklist":
        if (
          location.split("/")[1] === "checklist" ||
          location.includes("/checklist/details")
          // location.includes('clients') ||
          // location.includes('/client-overview/') ||
          // location.includes('/contracts') ||
          // location.includes('/contracts/add') ||
          // location.includes('/contracts/edit')
        ) {
          classtring = "active";
        }
        break;
      case "/sub-projects":
        if (
          location.split("/")[1] === "sub-projects"
          // ||
          // location.includes('/sub-project/add') ||
          // location.includes('/sub-projects') ||
          // location.includes('/sub-projects/edit') ||
          // location.includes('/sub-projects/variation-orders/')
        ) {
          classtring = "active";
        }
        break;
      case "/activity-files":
        if (
          location.split("/")[1].includes("activity-files") ||
          location.includes("/qc2") ||
          // location.includes('/activity-files/add')
          location.split("/")[1].includes("activity-file")
        ) {
          classtring = "active";
        }
        break;
      case "/deliverables":
        if (location.includes("/deliverables")) {
          classtring = "active";
        }
        break;
      case "/timesheets":
        if (location.includes("/timesheets")) {
          classtring = "active";
        }
        break;
      case "/project-queries":
        if (
          location.split("/")[1].includes("project-queries") ||
          location.includes("/project-query-overview")

          // location.includes('/project-queries-by-sub-project') ||
          // location.includes('/project-query-overview') ||
          // location.includes('/raise-project-query') ||
          // location.includes('/project-queries')
        ) {
          classtring = "active";
        }
        break;
      case "/variation-orders":
        if (
          location.split("/")[1].includes("variation-orders")

          // location.includes('/variation-orders') ||
          // location.includes('/variation-orders/view-by-sub-project') ||
          // location.includes('/variation-orders/issue') ||
          // location.includes('/variation-orders/add-feedback') ||
          // location.includes('/variation-orders/draft')
        ) {
          classtring = "active";
        }
        break;
      case "/conference":
        if (
          location.split("/")[1] === "conference" ||
          location.includes("/edit-conference/") ||
          location === "/add-conference" ||
          location.includes("/book-conference") ||
          location.includes("/view-conference/") ||
          location.includes("/edit-book-conference")
        ) {
          classtring = "active";
        }
        break;
      case "/trash":
        if (location.includes("trash")) {
          classtring = "active";
        }
        break;
      case "/reports":
        if (location.split("/")[1] === "reports") {
          classtring = "active";
        }
        break;
      case "/analytical-reports":
        if (location.split("/")[1] === "analytical-reports") {
          classtring = "active";
        }
        break;

      case "/reports-builder":
        if (
          location.split("/")[1] === "reports-builder" ||
          location.includes("/add-reports-builder") ||
          location.includes("/edit-reports-builder/")
        ) {
          classtring = "active";
        }
        break;

      case "/feedback":
        if (location.split("/")[1].includes("feedback")) {
          classtring = "active";
        }
        break;

      case "/meeting-notes":
        if (
          location.split("/")[1] === "meeting-notes" ||
          location.includes("/add-meeting-notes") ||
          location.includes("/edit-meeting-notes")
        ) {
          classtring = "active";
        }
        break;

      case "/resource-planner":
        if (
          location.split("/")[1] === "resource-planner" ||
          location.includes("/resource-planner-add") ||
          location.includes("/resource-planner-edit")
        ) {
          classtring = "active";
        }
        break;

      default:
        break;
    }

    return classtring;
  };

  const permissionsMatrix = useSelector(
    (state) => state.permissions.permissionsMatrix
  );

  const checkOverallPermissions = (moduleAliasArray) => {
    if (permissionsMatrix && permissionsMatrix.length > 0) {
      let returnValue = false;

      for (let moduleAlias of moduleAliasArray) {
        const permission = permissionsMatrix.find(
          (element) =>
            element.permission.module.alias == moduleAlias &&
            element.permission.alias == "view"
        );
        if (permission && permission.flag == 1) {
          returnValue = true;
          break;
        }
      }
      return returnValue;
    }
    return false;
  };

  return (
    <>
      <li class="nav-item sidebar-actions">
        <span class="nav-link">
          <div class="">
            <h6 class="font-weight-normal mb-1">Home</h6>
          </div>
        </span>
      </li>
      <li className={`nav-item nav-click ${active("/dashboard")}`}>
        <NavLink to="/dashboard" className={`nav-link`}>
          <i className="menu-icon">
            <Dashboardsidebar width={18} height={18} />
          </i>
          <span className="menu-title">Dashboard</span>
        </NavLink>
      </li>

      {!isMobile && (
        <li className={`nav-item nav-click ${active("/eapps")}`}>
          <NavLink to="/eapps" className={`nav-link`}>
            <i className="menu-icon">
              <EAppsicon width={18} height={18} />
            </i>
            <span className="menu-title">eApplications</span>
          </NavLink>
        </li>
      )}

      <div class="border-bottom">&nbsp;</div>
      {checkOverallPermissions(["employees"]) && !isMobile && (
        <>
          <li class="nav-item sidebar-actions">
            <span class="nav-link">
              <div class="">
                <h6 class="font-weight-normal mb-1">Human Resources</h6>
              </div>
            </span>
          </li>

          <li className={`nav-item nav-click ${active("/employees")}`}>
            <PermissionGateway
              moduleAlias={"employees"}
              permissionAlias={"view"}
            >
              <Link className="nav-link" to="/employees/active">
                <i className="menu-icon">
                  <Employeesidebar width={18} height={18} />
                </i>
                <span className="menu-title">Employees</span>
              </Link>
            </PermissionGateway>
          </li>
          <div class="border-bottom">&nbsp;</div>
        </>
      )}

      {/* {checkOverallPermissions(['organisations', 'contacts', 'tasks']) && (
        <>
          <li class="nav-item sidebar-actions">
            <span class="nav-link">
              <div class="">
                <h6 class="font-weight-normal mb-1">Sales</h6>
              </div>
            </span>
          </li>

          <li className={`nav-item nav-click ${active('/organisations')}`}>
            <PermissionGateway
              moduleAlias={'organisations'}
              permissionAlias={'view'}
            >
              <Link className="nav-link" to="/organisations">
                <i className="menu-icon">
                  <Organisationsidebar width={18} height={17} />
                </i>
                <span className="menu-title">Organisations</span>
              </Link>
            </PermissionGateway>
          </li>

          <li className={`nav-item nav-click ${active('/contacts')}`}>
            <PermissionGateway
              moduleAlias={'contacts'}
              permissionAlias={'view'}
            >
              <Link className="nav-link" to="/contacts">
                <i className="menu-icon">
                  <Contactsidebar width={18} height={18} />
                </i>
                <span className="menu-title">Contacts</span>
              </Link>
            </PermissionGateway>
          </li>

          <div class="border-bottom">&nbsp;</div>
        </>
      )} */}

      {checkOverallPermissions([
        "projects",
        "sub_projects",
        "activity_files",
        "pqs",
        "clients",
        "timesheets",
        "contacts",
      ]) && (
          <>
            <li class="nav-item sidebar-actions">
              <span class="nav-link">
                <div class="">
                  <h6 class="font-weight-normal mb-1">Production</h6>
                </div>
              </span>
            </li>

            <li className={`nav-item nav-click ${active("/projects")}`}>
              <PermissionGateway
                moduleAlias={"projects"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/projects/onboarding">
                  <i className="menu-icon">
                    <Projectsidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Projects</span>
                </Link>
              </PermissionGateway>
            </li>

            <li className={`nav-item nav-click ${active("/sub-projects")}`}>
              <PermissionGateway
                moduleAlias={"sub_projects"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/sub-projects/work-in-progress">
                  <i className="menu-icon">
                    <Subprojectsidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Sub-Projects</span>
                </Link>
              </PermissionGateway>
            </li>

            <li className={`nav-item nav-click ${active("/activity-files")}`}>
              <PermissionGateway
                moduleAlias={"activity_files"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/activity-files/not-started">
                  <i className="menu-icon">
                    <Activitysidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Activity/Files</span>
                </Link>
              </PermissionGateway>
            </li>

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/timesheets")}`}>
                <PermissionGateway
                  moduleAlias={"timesheets"}
                  permissionAlias={"view"}
                >
                  <Link className="nav-link" to="/timesheets">
                    <i className="menu-icon">
                      <SidebarTimesheet width={18} height={18} />
                    </i>
                    <span className="menu-title">Timesheet</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}
            {/* {!isMobile && <li className={`nav-item nav-click ${active('/resource-planner')}`}>
              <PermissionGateway
                moduleAlias={'resource_planner'}
                permissionAlias={'view'}
              >
                <Link className="nav-link" to="/resource-planner">
                  <i className="menu-icon">
                    <ResourcePlannerIcon width={18} height={18} />
                  </i>
                  <span className="menu-title">Resource Planner</span>
                </Link>
              </PermissionGateway>
            </li>} */}

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/project-queries")}`}>
                <PermissionGateway moduleAlias={"pqs"} permissionAlias={"view"}>
                  <Link className="nav-link" to="/project-queries">
                    <i className="menu-icon">
                      <Projectquerysidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Project Queries</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/variation-orders")}`}>
                <PermissionGateway moduleAlias={"vo"} permissionAlias={"view"}>
                  <Link className="nav-link" to="/variation-orders">
                    <i className="menu-icon">
                      <Variotionordrsidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Variation Orders</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            <li className={`nav-item nav-click ${active("/deliverables")}`}>
              <PermissionGateway
                moduleAlias={"deliverables"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/deliverables/overdue">
                  <i className="menu-icon">
                    <Deliverablesidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Deliverables</span>
                </Link>
              </PermissionGateway>
            </li>

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/clients")}`}>
                <PermissionGateway
                  moduleAlias={"clients"}
                  permissionAlias={"view"}
                >
                  <Link className="nav-link" to="/clients">
                    <i className="menu-icon">
                      <Clientsidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Clients</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/checklist")}`}>
                <PermissionGateway
                  moduleAlias={"checklists"}
                  permissionAlias={"view"}
                >
                  <Link className="nav-link" to="/checklist">
                    <i className="menu-icon">
                      <Checklistsidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Checklist</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/contacts")}`}>
                <PermissionGateway
                  moduleAlias={"contacts"}
                  permissionAlias={"view"}
                >
                  <Link className="nav-link" to="/contacts">
                    <i className="menu-icon">
                      <Contactsidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Contacts</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/feedback")}`}>
                <PermissionGateway
                  moduleAlias={"client_feedback"}
                  permissionAlias={"view"}
                >
                  <Link className="nav-link" to="/feedback">
                    <i className="menu-icon">
                      <ClientFeedbackicon width={18} height={18} />
                    </i>
                    <span className="menu-title">Feedback</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/meeting-notes")}`}>
                <PermissionGateway
                  moduleAlias={"meeting_notes"}
                  permissionAlias={"view"}
                >
                  <Link className="nav-link" to="/meeting-notes/live">
                    <i className="menu-icon">
                      <MintsMeetingsidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Meeting Notes</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            <div class="border-bottom">&nbsp;</div>
          </>
        )}

      {checkOverallPermissions([
        "hr_settings",
        "sales_settings",
        "facilities_settings",
        "emanage_settings",
        "production_settings",
        "trash",
        "conference",
      ]) && (
          <>
            <li class="nav-item sidebar-actions">
              <span class="nav-link">
                <div class="">
                  <h6 class="font-weight-normal mb-1">General</h6>
                </div>
              </span>
            </li>
            <li className={`nav-item nav-click ${active("/conference")}`}>
              <PermissionGateway
                moduleAlias={"conference"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/conference/calender-view">
                  <i className="menu-icon">
                    <Conferencesidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Conference Rooms</span>
                </Link>
              </PermissionGateway>
            </li>

            {!isMobile && (
              <li className={`nav-item nav-click ${active("/trash")}`}>
                <PermissionGateway moduleAlias={"trash"} permissionAlias={"view"}>
                  <Link className="nav-link" to="/trash/projects">
                    <i className="menu-icon">
                      <Trashsidebar width={18} height={18} />
                    </i>
                    <span className="menu-title">Trash</span>
                  </Link>
                </PermissionGateway>
              </li>
            )}

            {!isMobile && <div class="border-bottom">&nbsp;</div>}
          </>
        )}
      <li class="nav-item sidebar-actions">
        <span class="nav-link">
          <div class="">
            <h6 class="font-weight-normal mb-1">Reports</h6>
          </div>
        </span>
      </li>
      {!isMobile && (
        <li className={`nav-item nav-click ${active("/reports")}`}>
          <PermissionGateway
            moduleAlias={"reports"}
            permissionAlias={"view_reports"}
          >
            <Link className="nav-link" to="/reports/daily-timesheet-reports">
              <i className="menu-icon">
                <ReportSidebar width={18} height={18} />
              </i>
              <span className="menu-title">Timesheet</span>
            </Link>
          </PermissionGateway>
        </li>
      )}
      {!isMobile && (
        <li className={`nav-item nav-click ${active("/analytical-reports")}`}>
          <PermissionGateway
            moduleAlias={"reports"}
            permissionAlias={"view_analytics"}
          >
            <Link className="nav-link" to="/analytical-reports">
              <i className="menu-icon">
                <AnalyticsSidebar width={18} height={18} />
              </i>
              <span className="menu-title">Analytics</span>
            </Link>
          </PermissionGateway>
        </li>
      )}
      {!isMobile && (
        <li className={`nav-item nav-click ${active("/reports-builder")}`}>
          <PermissionGateway
            moduleAlias={"reports"}
            permissionAlias={"view_report_builder"}
          >
            <Link className="nav-link" to="/reports-builder">
              <i className="menu-icon">
                <ReportBuilderSidebar width={18} height={18} />
              </i>
              <span className="menu-title">Builder</span>
            </Link>
          </PermissionGateway>
        </li>
      )}
      {!isMobile && <div class="border-bottom">&nbsp;</div>}

      {checkOverallPermissions([
        "emanage_settings",
        "hr_settings",
        "production_settings",
      ]) &&
        !isMobile && (
          <>
            <li class="nav-item sidebar-actions">
              <span class="nav-link">
                <div class="">
                  <h6 class="font-weight-normal mb-1">Settings</h6>
                </div>
              </span>
            </li>

            <li className={`nav-item nav-click ${active("/emanage-settings")}`}>
              <PermissionGateway
                moduleAlias={"emanage_settings"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/emanage-settings/modules">
                  <i className="menu-icon">
                    <Emanagesettingsidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">eManage Settings</span>
                </Link>
              </PermissionGateway>
            </li>

            <li className={`nav-item nav-click ${active("/hr-settings")}`}>
              <PermissionGateway
                moduleAlias={"hr_settings"}
                permissionAlias={"view"}
              >
                <Link className="nav-link" to="/hr-settings/departments">
                  <i className="menu-icon">
                    <HRsidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Human Resources Settings</span>
                </Link>
              </PermissionGateway>
            </li>

            <li
              className={`nav-item nav-click ${active("/production-settings")}`}
            >
              <PermissionGateway
                moduleAlias={"production_settings"}
                permissionAlias={"view"}
              >
                <Link
                  className="nav-link"
                  to="/production-settings/types-of-work"
                >
                  <i className="menu-icon">
                    <Emanagesettingsidebar width={18} height={18} />
                  </i>
                  <span className="menu-title">Production Settings</span>
                </Link>
              </PermissionGateway>
            </li>
          </>
        )}
    </>
  );
};

export default Sidebar;
